// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-authors-tsx": () => import("./../src/pages/authors.tsx" /* webpackChunkName: "component---src-pages-authors-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-hvor-mange-vegetarer-er-der-i-danmark-tsx": () => import("./../src/pages/info/hvor-mange-vegetarer-er-der-i-danmark.tsx" /* webpackChunkName: "component---src-pages-info-hvor-mange-vegetarer-er-der-i-danmark-tsx" */),
  "component---src-pages-info-vegetar-vs-veganer-vs-pescetar-vs-fleksitar-tsx": () => import("./../src/pages/info/vegetar-vs-veganer-vs-pescetar-vs-fleksitar.tsx" /* webpackChunkName: "component---src-pages-info-vegetar-vs-veganer-vs-pescetar-vs-fleksitar-tsx" */),
  "component---src-pages-kategorier-tsx": () => import("./../src/pages/kategorier.tsx" /* webpackChunkName: "component---src-pages-kategorier-tsx" */),
  "component---src-pages-konkurrence-tsx": () => import("./../src/pages/konkurrence.tsx" /* webpackChunkName: "component---src-pages-konkurrence-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-opskrifter-tsx": () => import("./../src/pages/opskrifter.tsx" /* webpackChunkName: "component---src-pages-opskrifter-tsx" */),
  "component---src-pages-privatpolitik-tsx": () => import("./../src/pages/privatpolitik.tsx" /* webpackChunkName: "component---src-pages-privatpolitik-tsx" */),
  "component---src-pages-tak-tsx": () => import("./../src/pages/tak.tsx" /* webpackChunkName: "component---src-pages-tak-tsx" */),
  "component---src-templates-article-template-tsx": () => import("./../src/templates/ArticleTemplate.tsx" /* webpackChunkName: "component---src-templates-article-template-tsx" */),
  "component---src-templates-author-template-tsx": () => import("./../src/templates/AuthorTemplate.tsx" /* webpackChunkName: "component---src-templates-author-template-tsx" */),
  "component---src-templates-category-template-tsx": () => import("./../src/templates/CategoryTemplate.tsx" /* webpackChunkName: "component---src-templates-category-template-tsx" */),
  "component---src-templates-story-blok-template-tsx": () => import("./../src/templates/StoryBlokTemplate.tsx" /* webpackChunkName: "component---src-templates-story-blok-template-tsx" */),
  "component---src-templates-tags-template-tsx": () => import("./../src/templates/TagsTemplate.tsx" /* webpackChunkName: "component---src-templates-tags-template-tsx" */)
}

