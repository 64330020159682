module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Recoleta"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Vegetar.dk","short_name":"Vegetar.dk","start_url":"/","display":"minimal-ui","icon":"src/images/only-logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"48da57d2d97128a0f6ea16ab4a70bc23"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-173907292-1","head":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Vegetar","short_name":"Vegetar","start_url":"/","icon":"src/images/only-logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"48da57d2d97128a0f6ea16ab4a70bc23"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
